<template>
  <v-dialog v-model="cancel" class="dialog" max-width="600" persistent>
    <v-card class="card">
      <h2 class="text-center font-weight-regular">Are you sure to cancel?</h2>
      <v-row>
        <v-col class="mt-7 mb-4 text-center" cols="12">
          <v-btn
            class="mr-5 font-weight-bold"
            style="box-shadow: none"
            @click="handleClickCancel"
            height="42"
            >Yes
          </v-btn>
          <v-btn
            class="button font-weight-bold"
            height="42"
            @click="cancelModal"
            >No
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CancelModal",
  props: {
    cancel: Boolean,
    handleClickCancel: Function,
    cancelModal: Function,
  },
};
</script>

<style scoped></style>
